<template>
    <v-section-group>
        <v-model-table ref="jobTable" v-bind="tableDataSource" :columns="columns" :query="query" :show-toolbar="showToolbar" @selected-game-updated="$emit('selected-game-updated')">
            <template v-slot:column_order_line_id="{ row }">
                <v-link @click="selectOrderLine(row.order_line_id)">
                    {{ row.order_line_id }}
                </v-link>
            </template>

            <template v-slot:column_order="{ row }">
                <v-link :to="{ name: 'orders.show', params: { order: row.order.order_id } }">
                    {{ row.order.order_id }}
                </v-link>
            </template>

            <template v-slot:column_is_fast_pass="{ row }">
                <v-text class="flex items-center justify-center">
                    <svg class="h-5 w-5 text-white" :class="(row.is_fast_pass) ? 'text-secondary-500' : 'text-gray-500'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd" />
                    </svg>
                </v-text>
            </template>

            <template v-slot:column_customer_name="{ row }">
                <v-text>
                    {{ row.customer_name | customerName }}
                </v-text>
            </template>

            <template v-slot:column_product_name="{ row }">
                <v-text>
                    {{ row.product_name }}
                </v-text>

                <div class="w-full flex justify-start content-center space-x-2">
                    <template v-for="(platform, index) in row.game_platforms">
                        <div :key="'platform_' + index" class="flex items-center space-x-1">
                            <v-icon class="text-gray-500 fill-current" :name="platform.slug" :alt="platform.name" />
                            <span> {{ platform.name }}</span>
                        </div>
                    </template>
                </div>
            </template>

            <template v-slot:column_order_line_status_name="{ row }">
                <v-badge :color="row.order_line_status.color">
                    {{ row.order_line_status.name }}
                </v-badge>
            </template>

            <template v-slot:column_price="{ row }">
                <v-text align="right">
                    {{ row.display_price | numberFormat }}
                </v-text>
            </template>

            <template v-slot:column_actions="{ row }">
                <template v-if="row.is_taken && (row.taken_by.user_id == $me.getUser().user_id)">
                    <v-button color="secondary" @click.stop="toggleTakeOrderLine(row)">
                        <i class="fa fa-minus-circle" />
                        Cancel
                    </v-button>
                </template>

                <template v-else>
                    <v-button color="secondary" @click.stop="toggleTakeOrderLine(row)">
                        <i class="fa fa-plus-circle" />
                        Take Job
                    </v-button>
                </template>
            </template>
        </v-model-table>

        <v-job-slide-over v-if="selectedOrderLineId" v-model="jobSlideOver" :job-id="selectedOrderLineId" @job-updated="jobUpdated" />
    </v-section-group>
</template>

<script>
import JobService from "@/services/modules/job-service";
import JobSlideOver from "./job-slide-over.vue";

export default {
    name: "JobTable",
    components: {
        "v-job-slide-over": JobSlideOver,
    },
    props: {
        dataFunction: {
            type: Function,
            required: false,
            default: null,
        },
        query: {
            type: Object,
            required: false,
            default: () => {},
        },
        showToolbar: {
            type: Boolean,
            required: false,
            default: () => true,
        },
    },
    data() {
        return {
            jobService: JobService,
            selectedOrderLineId: null,
            jobSlideOver: false,
        };
    },
    computed: {
        /**
         * Returns the attributes that should be bound to the table.
         */
        tableDataSource() {
            if (this.dataFunction) {
                return {
                    dataFunction: this.dataFunction,
                };
            }

            if (this.service) {
                return {
                    service: this.service,
                };
            }

            return {
                module: "orderLineModule",
            };
        },
        columns() {
            const tableColumns = {
                order_line_id: {
                    label: "Job",
                    fullWidth: false,
                },
                is_fast_pass: {
                    label: "Fast Pass",
                    formatter: (row) => (row.is_fast_pass ? "Yes" : "No"),
                    fullWidth: false,
                    align: "center",
                },
                customer_name: {
                    label: "Customer",
                    formatter: (row) => row.customer_name,
                    fullWidth: false,
                    truncate: true,
                },
                product_name: {
                    label: "Product",
                    formatter: (row) => row.product_name,
                    fullWidth: true,
                    truncate: true,
                },
                player_environments: {
                    label: "Env",
                    formatter(row) {
                        const playerEnvironments = row.player_environments.map((playerEnvironment) => playerEnvironment);
                        return playerEnvironments.join();
                    },
                    fullWidth: false,
                },
                order_line_status_name: {
                    label: "Status",
                    formatter: (row) => row.order_line_status_name,
                },
            };

            if (this.$me.hasPermission("order-lines.price.read")) {
                tableColumns.quantity = {
                    label: "Qty",
                    align: "right",
                };

                tableColumns.price = {
                    label: "Price",
                    align: "right",
                    formatter: (row) => row.display_price,
                };
            }

            return tableColumns;
        },
    },
    watch: {
        "$route.query": {
            handler({ jobId, jobSlideOver }) {
                if (!jobId || !jobSlideOver) {
                    this.selectedOrderLineId = null;
                    this.jobSlideOver = false;
                    return;
                }

                this.selectedOrderLineId = jobId;
                this.jobSlideOver = true;
            },
            immediate: true,
            deep: true,
        },
        jobSlideOver: {
            handler(value) {
                if (!value) {
                    this.$router.replace({
                        query: {
                            ...this.$route.query,
                            jobId: undefined,
                            jobSlideOver: undefined,
                        },
                    });
                }
            },
        },
    },
    methods: {
        toggleTakeOrderLine({ order_line_id: orderLineId, is_taken: isTaken = false }) {
            const payload = {
                take_order_line: !isTaken,
            };

            this.$store.dispatch("orderLineModule/updateItem", { id: orderLineId, payload })
                .then(() => {})
                .catch(() => {});
        },
        selectOrderLine(orderLineId) {
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    jobId: orderLineId || undefined,
                    jobSlideOver: orderLineId ? true : undefined,
                },
            });
        },
        toggleJobSlideOver() {
            this.jobSlideOver = !this.jobSlideOver;
        },
        jobUpdated() {
            this.$refs.jobTable.fetchQuery = { ...this.$refs.jobTable.fetchQuery };
        },
    },
};
</script>
