var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-section-group',[_c('v-model-table',_vm._b({ref:"jobTable",attrs:{"columns":_vm.columns,"query":_vm.query,"show-toolbar":_vm.showToolbar},on:{"selected-game-updated":function($event){return _vm.$emit('selected-game-updated')}},scopedSlots:_vm._u([{key:"column_order_line_id",fn:function(ref){
var row = ref.row;
return [_c('v-link',{on:{"click":function($event){return _vm.selectOrderLine(row.order_line_id)}}},[_vm._v(" "+_vm._s(row.order_line_id)+" ")])]}},{key:"column_order",fn:function(ref){
var row = ref.row;
return [_c('v-link',{attrs:{"to":{ name: 'orders.show', params: { order: row.order.order_id } }}},[_vm._v(" "+_vm._s(row.order.order_id)+" ")])]}},{key:"column_is_fast_pass",fn:function(ref){
var row = ref.row;
return [_c('v-text',{staticClass:"flex items-center justify-center"},[_c('svg',{staticClass:"h-5 w-5 text-white",class:(row.is_fast_pass) ? 'text-secondary-500' : 'text-gray-500',attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z","clip-rule":"evenodd"}})])])]}},{key:"column_customer_name",fn:function(ref){
var row = ref.row;
return [_c('v-text',[_vm._v(" "+_vm._s(_vm._f("customerName")(row.customer_name))+" ")])]}},{key:"column_product_name",fn:function(ref){
var row = ref.row;
return [_c('v-text',[_vm._v(" "+_vm._s(row.product_name)+" ")]),_c('div',{staticClass:"w-full flex justify-start content-center space-x-2"},[_vm._l((row.game_platforms),function(platform,index){return [_c('div',{key:'platform_' + index,staticClass:"flex items-center space-x-1"},[_c('v-icon',{staticClass:"text-gray-500 fill-current",attrs:{"name":platform.slug,"alt":platform.name}}),_c('span',[_vm._v(" "+_vm._s(platform.name))])],1)]})],2)]}},{key:"column_order_line_status_name",fn:function(ref){
var row = ref.row;
return [_c('v-badge',{attrs:{"color":row.order_line_status.color}},[_vm._v(" "+_vm._s(row.order_line_status.name)+" ")])]}},{key:"column_price",fn:function(ref){
var row = ref.row;
return [_c('v-text',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm._f("numberFormat")(row.display_price))+" ")])]}},{key:"column_actions",fn:function(ref){
var row = ref.row;
return [(row.is_taken && (row.taken_by.user_id == _vm.$me.getUser().user_id))?[_c('v-button',{attrs:{"color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleTakeOrderLine(row)}}},[_c('i',{staticClass:"fa fa-minus-circle"}),_vm._v(" Cancel ")])]:[_c('v-button',{attrs:{"color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.toggleTakeOrderLine(row)}}},[_c('i',{staticClass:"fa fa-plus-circle"}),_vm._v(" Take Job ")])]]}}])},'v-model-table',_vm.tableDataSource,false)),(_vm.selectedOrderLineId)?_c('v-job-slide-over',{attrs:{"job-id":_vm.selectedOrderLineId},on:{"job-updated":_vm.jobUpdated},model:{value:(_vm.jobSlideOver),callback:function ($$v) {_vm.jobSlideOver=$$v},expression:"jobSlideOver"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }